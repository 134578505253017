import styled from "@emotion/styled";

export interface InputProps {
    darkMode?: boolean;
}

export const Input = styled.input<InputProps>`
    display: block;
    outline: none;
    box-sizing: border-box;
    border: none;
    width: 100%;
    margin: 0;
    padding: 8px;
    border-bottom: 1px solid ${props => props.darkMode ? "#CACACA" : "#757575"};
    margin-bottom: 0.5em;
    background-color: ${props => props.darkMode ? "#11121350" : "white"};

    font-family: "Barlow";
    font-size: 1.1em;
    line-height: 1.41;
    color: ${props => props.darkMode ? "white" : "black"};

    border-radius: 0;

    :focus,
    :active {
        border-bottom: 1px solid ${props => props.darkMode ? "rgba(178, 179, 182, 0.3)" : "rgba(58, 59, 62, 0.3)"};
        background-color: ${props => props.darkMode ? "#111213" : "white"};
    }

    ::placeholder {
        font-family: Barlow, sans-serif;
        font-size: 1.1em;
        line-height: 1.41;
        color: ${props => props.darkMode ? "#757575" : "#757575"};
    }

    :disabled {
        border-bottom: 1px dotted #757575;
    }

    :invalid {
        border-bottom: 1px solid #fc172f;
    }
`;
