import * as React from 'react';
import { Router } from "@reach/router";
import styled from "@emotion/styled";
import axios from "axios";
import { PageTitle } from "@fitplan/lib/components/Header/PageTitle";
import { PageSubTitle } from "@fitplan/lib/components/Header/PageSubTitle";
import { Input } from "@fitplan/lib/components/Input";
import { BrandButton } from "@fitplan/lib/components/Button/BrandButton";
import { Polygon } from "@fitplan/lib/components/Chevron/Polygon";

import localize from "../hoc/Localize";
import PageWrapper from "../components/pageWrapper/PageWrapper";
import Redirect from "../components/Redirect";
import { urls } from "../config";
import { reportException } from "../utils/sentry";

export interface Props {
    path: string;
    resetToken?: string;
}


const ResetForm: React.FunctionComponent<Props> = ({ path, resetToken, ...props }) => {
    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const [submitted, setSubmitted] = React.useState(false);
    const [pending, setPending] = React.useState(false);
    const strings = {
        title: "Reset Password",
        successTitle: "Successfully changed password. Please try logging in again!",
        error: "An unknown error occurred. Please try again later.",
        submit: "Update",
    }
    if (!resetToken) {
        return <Redirect to="/" />;
    }
    return (
        <Container onSubmit={async (event) => {
            event.preventDefault();
            setPending(true);
            setError("");
            try {
                const response = await axios({
                    method: "POST",
                    url: `${urls.resetPassword}`,
                    data: { password, token: resetToken },
                });
                const { result, error } = response.data;
                if (result) {
                    setSubmitted(true);
                }
            } catch (err) {
                if (err.response && err.response.data) {
                    setError(strings.error);
                } else {
                    reportException(err);
                }
            }
            setPending(false);
        }}>
            <PageTitle>{strings.title}</PageTitle>
            {submitted && <SubTitle>{strings.successTitle}</SubTitle>}
            {!submitted && (
                <>
                    <Password type="password" value={password} placeholder="New Password" onChange={event => {
                        setPassword(event.target.value);
                    }} />
                    <Password type="password" value={confirmPassword} placeholder="Confirm New Password" onChange={event => {
                        setConfirmPassword(event.target.value);
                    }} />
                    {error && <Error>{error}</Error>}
                    <Submit disabled={password.length < 6 || password !== confirmPassword || pending} type="submit">{strings.submit}</Submit>
                </>
            )}    
        </Container>
    );
}

const Container = styled.form`
    display: flex;
    flex-direction: column;

    justify-content: center;
    justify-items: center;
    width: 100%;

    padding: 16px;
    box-sizing: border-box;
`;

const SubTitle = styled(PageSubTitle)`
    margin-top: 24px;
`;

const Password = styled(Input)`
    flex: 0 0 auto;
    width: 100%;
    max-width: 400px;
    margin: auto;

    border-radius: 0;
`;

const Submit = styled(BrandButton)`
    flex: 0 0 auto;

    width: 200px;
    line-height: 24px;
    margin: auto;
    margin-top: 16px;
`;

const Error = styled.p`
    color: #f10364;
    margin-top: 8px;

    text-align: center;
`;

const ResetPassword: React.FunctionComponent = props => (
    <PageWrapper {...props}>
        <Router>
            <ResetForm path="/reset-password" />
            <ResetForm path="/reset-password/:resetToken" />
        </Router>
        <Polygon polygonColor="#111213" borderColor="#00ffb3" />
    </PageWrapper>
);

export default localize(ResetPassword);
