import { isProduction } from "../config";

export const isSentryActive = () => !!(typeof window !== "undefined" && isProduction() && (window as any).Sentry);

export const reportException = (error: Error) => {
    if (isSentryActive()) {
        (window as any).Sentry.captureException(error);
    }
}

export const setSentryUser = (user: any) => {
    if (isSentryActive()) {
        (window as any).Sentry.configureScope((scope: any) => {
            scope.setUser(user);
        });
    }
}
